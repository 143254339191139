import { Link } from "gatsby"
import React from "react"
import LogoText from "./images/LogoText"

const Header = () => (
  <header className="mx-16 py-6 flex justify-center items-center md:justify-start md:items-center">
    <Link to="/">
      <LogoText />
    </Link>
  </header>
)

export default Header
