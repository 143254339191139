import React from "react"

const Footer = () => {
  return (
    <footer className="text-center py-3 text-md">
      © {new Date().getFullYear()}, Talsho Technologies LLP
    </footer>
  )
}

export default Footer
